// fetchShows

export const fetchShows = async () => {
    try {
      const response = await fetch(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${process.env.REACT_APP_AIRTABLE_SHOW_TABLE_NAME}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data.records;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };
  
  // fetchCatalogue

export const fetchCatalogue = async () => {
    try {
      const response = await fetch(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${process.env.REACT_APP_AIRTABLE_CATALOG_TABLE_NAME}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data.records;
    } catch (error) {
      console.error('Error fetching catalogue data: ', error);
      throw error;
    }
  };

  // fetch show details

export const fetchShowDetails = async (showName) => {
  try {
      const filter = `Name="${showName}"`;
      const encodedFilter = encodeURIComponent(filter);
      const url = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${process.env.REACT_APP_AIRTABLE_SHOW_TABLE_NAME}?filterByFormula=${encodedFilter}`;

      const response = await fetch(url, {
          headers: {
              Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
          }
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.records.length > 0 ? data.records[0] : null; // Return the first matching show or null if not found
  } catch (error) {
      console.error('Error fetching show details:', error);
      throw error;
  }
};

export const createRecord = async (data) => {
  try {
      const url = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${process.env.REACT_APP_AIRTABLE_RECEIVE_TABLE_NAME}`;

      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
          },
          body: JSON.stringify({ fields: data }),
      });

      if (!response.ok) {
          const errorBody = await response.json();
          throw new Error(`Error creating record: ${response.status} ${errorBody.error}`);
      }

      return await response.json();
  } catch (error) {
      console.error('Error creating record:', error);
      throw error;
  }
};

  