import React, { useState } from 'react';
import { Container, Accordion, Col, Row, Button } from 'react-bootstrap';
import { createRecord } from '../api/airtableApi';
import './OrderConfirmation.css';
import FinalScreen from './FinalScreen';
import TermsAndConditionsModal from './TermsAndConditions';

const OrderConfirmation = ({ contactInfo, cartItems, showId, goBack, goBackToCatalog, goBackToContactInfo, cart, showName }) => {
    console.log('Received cart items in OrderConfirmation:', cartItems);
    console.log('cart', cart);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const {
        companyName,
        boothNumber,
        companyPhone,
        companyExt,
        companyEmail,
        companyAddress,
        companyAddress2,
        companyCity,
        companyState,
        companyZip,
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhone,
        contactExt,
        siteContactFirstName,
        siteContactLastName,
        siteContactEmail,
        siteContactPhone,
        siteContactExt,
        addOnsiteContact
    } = contactInfo;

    // Function to render grouped contact sections
    const renderGroupedContactSection = (label1, value1, label2, value2) => (
        <Row className="info-list">
            <Col>
                <p><small><strong>{label1}:</strong></small> {value1}</p>
            </Col>
            {value2 && 
                <Col>
                    <p><small><strong>{label2}:</strong></small> {value2}</p>
                </Col>
            }
        </Row>
    );

    // Function to render grouped contact sections
    const renderGroupedCompanySection = (label1, value1, label2, value2, label3, value3) => (
        <Row className="info-list">
            <Col>
                <p><small><strong>{label1}:</strong></small> {value1}</p>
            </Col>
            {value2 && 
                <Col>
                    <p><small><strong>{label2}:</strong></small> {value2}</p>
                </Col>
            }
            {value3 && 
                <Col>
                    <p><small><strong>{label3}:</strong></small> {value3}</p>
                </Col>
            }
        </Row>
    );

    // Calculate subtotal, service fee, and total
    const subtotal = cartItems.reduce((total, item) => total + item.subtotal, 0);
    const serviceFee = subtotal * 0.25;
    const total = subtotal + serviceFee;

    const handleOrderSubmit = async () => {
        setIsLoading(true);
        try {
            const orderDescriptions = cartItems.map(item => `${item.name} (Qty: ${item.quantity})${item.notes ? ' - ' + item.notes : ''} - Subtotal: ${item.subtotal.toFixed(2)}`).join(", ");
            const recordData = {
                // Fill this object with the fields and values from contactInfo and cartItems
                // Example: "CompanyName": contactInfo.companyName
                "Company Name": companyName,
                "Booth Name/Number": boothNumber,
                "Company Street Address": companyAddress,
                "Company Street Address 2": companyAddress2,
                "Company City": companyCity,
                "Company State": companyState,
                "Company Postal": companyZip,
                "Company Email": companyEmail,
                "Company Phone": companyPhone,
                "Company Phone Ext": companyExt,
                "Contact First Name": contactFirstName,
                "Contact Last Name": contactLastName,
                "Contact Email": contactEmail,
                "Contact Phone": contactPhone,
                "Contact Phone Ext": contactExt,
                "On Site Contact First Name": siteContactFirstName,
                "On Site Contact Last Name": siteContactLastName,
                "On Site Contact Email": siteContactEmail,
                "On Site Contact Phone": siteContactPhone,
                "On Site Contact Phone Ext": siteContactExt,
                "Order": orderDescriptions, // Ensure this is a single string
                "Subtotal": subtotal,
                "Service Fee": serviceFee,
                "Total": total,
                "Order Form": [showId]
                
            };
            console.log(recordData);

            const result = await createRecord(recordData);
            setIsSuccess(true);
            console.log('Record created:', result);
            // Additional logic after successful record creation
        } catch (error) {
            setIsError(true);
            console.error('Error submitting order:', error);

            // Handle error case
        } finally {
            setIsLoading(false);
        }
    };

    // Conditional rendering based on state
    if (isLoading || isError || isSuccess) {
        return (
            <FinalScreen
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                showName={showName}
            />
        );
    }

    return (
        <Container className="mt-5 order-confirmation-container">
            <h2 className="order-heading">Order Information & Confirmation</h2>
            <div className='flex-container'>
                <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Company Information</Accordion.Header>
                        <Accordion.Body>
                            <Row className='info-list'>
                                <Col>
                                    <p><small><strong>Booth Number:</strong></small> {boothNumber}</p>
                                </Col>
                            </Row>
                            <Row className="info-list">
                                <Col>
                                    <p><small><strong>Company Name:</strong></small> {companyName}</p>
                                </Col>
                            </Row>
                            <Row className="info-list">
                                <Col>
                                    <p><small><strong>Email:</strong></small> {companyEmail}</p>
                                </Col>
                            </Row>
                            {renderGroupedContactSection("Phone", companyPhone, "Ext", companyExt)}
                            {renderGroupedContactSection("Address", companyAddress, "Address 2", companyAddress2)}
                            {renderGroupedCompanySection("City", companyCity, "State", companyState, "Zip", companyZip)}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Contact Information</Accordion.Header>
                        <Accordion.Body>
                            {renderGroupedContactSection("First Name", contactFirstName, "Last Name", contactLastName)}
                            <Row className="info-list">
                                <Col>
                                    <p><small><strong>Email:</strong></small> {contactEmail}</p>
                                </Col>
                            </Row>
                            {renderGroupedContactSection("Phone", contactPhone, "Ext", contactExt)}
                        </Accordion.Body>
                    </Accordion.Item>
    
                    {addOnsiteContact && (
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>On Site Contact Information</Accordion.Header>
                            <Accordion.Body>
                                {renderGroupedContactSection("First Name", siteContactFirstName, "Last Name", siteContactLastName)}
                                <Row className="info-list">
                                    <Col>
                                        <p><small><strong>Email:</strong></small> {siteContactEmail}</p>
                                    </Col>
                                </Row>
                                {renderGroupedContactSection("Phone", siteContactPhone, "Ext", siteContactExt)}
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Accordion>
    
                <div className="right-section">
                    <Button variant="secondary" onClick={goBackToCatalog}>Back to Catalog</Button>
                    <Button variant="secondary" onClick={goBackToContactInfo}>Back to Contact & Company Information</Button>
                    <Row className="cart-section-confirm">
                        <h4 className="section-heading">Gear Items</h4>
                        {/* Cart Items */}
                        <Row className="cart-list-header">
                            <Row>
                                <Col className="cart-list-item">
                                    <p><small><strong>Item</strong></small></p>
                                </Col>
                                <Col>
                                    <p><small><strong>Notes</strong></small></p>
                                </Col>
                                <Col>
                                    <p><small><strong>Quantity</strong></small></p>
                                </Col>
                                <Col className="cart-list-subtotal">
                                    <p><small><strong>Item Subtotal</strong></small></p>
                                </Col>
                            </Row>
                        </Row>
    
                        <Row className="section-item">
                            {/* Map through cartItems array and display values */}
                            {cartItems.map((item, index) => (
                                <Row className="cart-list" key={index}>
                                    <Col className="cart-list-item">
                                        <p>{item.name}</p>
                                    </Col>
                                    <Col>
                                        <p>{item.notes}</p> {/* Ensure the notes are being displayed */}
                                    </Col>
                                    <Col>
                                        <p>{item.quantity}</p>
                                    </Col>
                                    <Col className="cart-list-subtotal">
                                        <p>${item.subtotal.toFixed(2)}</p>
                                    </Col>
                                </Row>
                            ))}
                        </Row>
                    </Row>
                    <Row className="subtotal-section">
                        <Row>
                            <Col xs={9}>
                                <h3><strong>Gear Subtotal:</strong></h3>
                            </Col>
                            <Col>
                                <h4><small><strong>${cartItems.reduce((total, item) => total + item.subtotal, 0)}</strong></small></h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9}>
                                <h3><small><strong>Service Fee:</strong></small></h3>
                                <small><i>Service fee includes Delivery, Set Up, Strike, Pick Up, & Onsite Support. No Dryage Fees.</i></small>
                            </Col>
                            <Col>
                                <h4><small><strong>${(cartItems.reduce((total, item) => total + item.subtotal, 0) * 0.25).toFixed(2)}</strong></small></h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9}>
                                <h2><strong>Order Total:</strong></h2>
                            </Col>
                            <Col>
                                <h4><strong>${(cartItems.reduce((total, item) => total + item.subtotal, 0) * 1.25).toFixed(2)}</strong></h4>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="link" onClick={() => setModalShow(true)}>Read Terms & Conditions</Button>
                            <TermsAndConditionsModal show={modalShow} handleClose={() => setModalShow(false)} />
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={handleOrderSubmit}>Submit Order</Button>
                        </Col>
                    </Row>
                </div>
            </div>
    
            {/* Additional logic and JSX */}
        </Container>
    );
}
export default OrderConfirmation;