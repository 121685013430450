import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Card, Container, Row, Col, Alert } from 'react-bootstrap';
import OrderFormCatalog from './OrderFormCatalog';
import OrderConfirmation from './OrderConfirmation';
import { fetchShowDetails } from '../api/airtableApi';

const OrderForm = () => {
  const { showName: urlShowName } = useParams();
  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState(1);
  const [formData, setFormData] = useState({
    companyName: '', boothNumber: '', companyAddress: '', companyAddress2: '', companyCity: '', companyState: '', companyZip: '', companyEmail: '', companyPhone: '', companyExt: '', contactFirstName: '', contactLastName: '', contactEmail: '', contactPhone: '', contactExt: '', addOnsiteContact: false, siteContactFirstName: '', siteContactLastName: '', siteContactEmail: '', siteContactPhone: '', siteContactExt: ''
  });
  const [cart, setCart] = useState(() => JSON.parse(localStorage.getItem('cart')) || {});
  const [showDetails, setShowDetails] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const getShowDetails = async () => {
      try {
        if (!urlShowName) throw new Error("Show name is not provided.");
        const details = await fetchShowDetails(urlShowName);
        if (!details) throw new Error("Show details not found.");
        setShowDetails(details);
      } catch (error) {
        setError(error.message);
        navigate('/');
      }
    };
    getShowDetails();
  }, [urlShowName, navigate]);
  

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleFormChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleNextSection = () => {
    const requiredFieldsBySection = {
      1: [], // No required fields for catalog section
      2: ['contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone'],
      3: ['companyName', 'companyAddress', 'companyCity', 'companyState', 'companyZip', 'companyEmail', 'companyPhone'],
    };

    const fieldLabels = {
      companyName: 'Company Name',
      boothNumber: 'Booth Number',
      companyAddress: 'Company Address',
      companyAddress2: 'P.O., Suite, etc.',
      companyCity: 'City',
      companyState: 'State',
      companyZip: 'Postal Code',
      companyEmail: 'Company Email',
      companyPhone: 'Company Phone',
      companyExt: 'Company Phone Ext',
      contactFirstName: 'Contact First Name',
      contactLastName: 'Contact Last Name',
      contactEmail: 'Contact Email',
      contactPhone: 'Contact Phone',
      contactExt: 'Contact Phone Ext',
      addOnsiteContact: 'Add Onsite Contact',
      siteContactFirstName: 'On Site Contact First Name',
      siteContactLastName: 'On Site Contact Last Name',
      siteContactEmail: 'On Site Contact Email',
      siteContactPhone: 'On Site Contact Phone',
      siteContactExt: 'On Site Contact Phone Ext'
    };

    const currentRequiredFields = requiredFieldsBySection[currentSection];
    let isValid = true;
    for (const field of currentRequiredFields) {
      if (!formData[field]) {
        isValid = false;
        const fieldLabel = fieldLabels[field];
        alert(`Please fill out the ${fieldLabel} field.`);
        break;
      }
    }

    if (formData.addOnsiteContact && currentSection === 2) {
      const onsiteContactFields = ['siteContactFirstName', 'siteContactLastName', 'siteContactEmail', 'siteContactPhone'];
      for (const field of onsiteContactFields) {
        if (!formData[field]) {
          isValid = false;
          const fieldLabel = fieldLabels[field];
          alert(`Please fill out the ${fieldLabel} field.`);
          break;
        }
      }
    }

    if (isValid) {
      setCurrentSection(currentSection + 1);
    }
  };

  const handlePrevSection = () => {
    setCurrentSection(currentSection - 1);
  };

  const handlePrevToCatalog = () => {
    setCurrentSection(1); // Go back to Catalog
  };

  const handlePrevToContactInfo = () => {
    setCurrentSection(2); // Go back to Contact Information
  };

  const renderInput = (label, name, type = 'text', placeholder = '', isRequired = false) => (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={4}>{label}</Form.Label>
      <Col sm={8}>
        <Form.Control
          required={isRequired}
          type={type}
          name={name}
          value={formData[name]}
          onChange={handleFormChange}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );

  const renderCompanyBoothInfo = () => (
    <Card>
      <Card.Body>
        <h4>Company Information</h4>
        {renderInput('Company Name', 'companyName', 'text', 'Company Name', true)}
        {renderInput('Booth Number', 'boothNumber', 'text', 'Booth Number', true)}
        {renderInput('Company Address', 'companyAddress', 'text', 'Company Street Address', true)}
        {renderInput('P.O., Suite, etc.', 'companyAddress2', 'text', 'P.O., Suite, etc.')}
        {renderInput('City', 'companyCity', 'text', 'City', true)}
        {renderInput('State', 'companyState', 'text', 'State', true)}
        {renderInput('Postal Code', 'companyZip', 'text', 'Postal Code', true)}
        {renderInput('Company Email', 'companyEmail', 'email', 'Company Email', true)}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Company Phone</Form.Label>
          <Col sm={5}>
            <Form.Control
              type="tel"
              name="companyPhone"
              value={formData.companyPhone}
              onChange={handleFormChange}
              placeholder="Company Phone"
            />
          </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              name="companyExt"
              value={formData.companyExt}
              onChange={handleFormChange}
              placeholder="Ext"
            />
          </Col>
        </Form.Group>
        <Button variant="primary" onClick={handleNextSection}>Next: Confirmation</Button>
      </Card.Body>
    </Card>
  );

  const renderContactInfo = () => (
    <Card>
      <Card.Body>
        <h4>Contact Information</h4>
        <Form.Group as={Row} className="mb-3">
          <Col sm={6}>
            <Form.Control
              type="text"
              name="contactFirstName"
              value={formData.contactFirstName}
              onChange={handleFormChange}
              placeholder="First Name"
            />
          </Col>
          <Col sm={6}>
            <Form.Control
              type="text"
              name="contactLastName"
              value={formData.contactLastName}
              onChange={handleFormChange}
              placeholder="Last Name"
            />
          </Col>
        </Form.Group>
        {renderInput('Email', 'contactEmail', 'email', 'Email')}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Phone</Form.Label>
          <Col sm={5}>
            <Form.Control
              type="tel"
              name="contactPhone"
              value={formData.contactPhone}
              onChange={handleFormChange}
              placeholder="Phone"
            />
          </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              name="contactExt"
              value={formData.contactExt}
              onChange={handleFormChange}
              placeholder="Ext"
            />
          </Col>
</Form.Group>
<Form.Group as={Row} className="mb-3">
  <Col sm={10}>
    <Form.Check
      type="checkbox"
      label="Add Onsite Contact"
      name="addOnsiteContact"
      checked={formData.addOnsiteContact}
      onChange={handleFormChange}
    />
  </Col>
</Form.Group>
{formData.addOnsiteContact && (
  <>
    <h4>On Site Contact Information</h4>
    {renderInput('Onsite Contact First Name', 'siteContactFirstName', 'text', '', formData.addOnsiteContact)}
    {renderInput('Onsite Contact Last Name', 'siteContactLastName', 'text', '', formData.addOnsiteContact)}
    {renderInput('Onsite Contact Email', 'siteContactEmail', 'email', '', formData.addOnsiteContact)}
    {renderInput('Onsite Contact Phone', 'siteContactPhone', 'tel', '', formData.addOnsiteContact)}
  </>
)}
<Button variant="secondary" onClick={handlePrevToCatalog}>Back to Catalog</Button>
<Button variant="primary" onClick={handleNextSection}>Next: Company Information</Button>
</Card.Body>
</Card>
);

const handleProceedToConfirmation = () => {
  localStorage.setItem('cart', JSON.stringify(cart));
  setCurrentSection(2); // Proceed to Contact Information section
};

if (currentSection === 1) {
  return (
    <OrderFormCatalog
      showName={showDetails ? showDetails.fields.Name : ''}
      cart={cart}
      setCart={setCart}
      proceed={handleProceedToConfirmation} // Pass the proceed function correctly
    />
  );
}

if (error) {
  return (
    <Container className="mt-5">
      <Alert variant="danger">{error}</Alert>
      <Button onClick={() => navigate('/')}>Back to Home</Button>
    </Container>
  );
}

return (
  <Container className="mt-5">
    <h2>Order Form for {showDetails ? showDetails.fields.Name : 'Loading…'}</h2>
    <Form>
      {currentSection === 1 && <OrderFormCatalog showName={showDetails ? showDetails.fields.Name : ''} cart={cart} setCart={setCart} proceed={handleProceedToConfirmation} />}
      {currentSection === 2 && renderContactInfo()}
      {currentSection === 3 && renderCompanyBoothInfo()}
      {currentSection === 4 && <OrderConfirmation contactInfo={formData} cartItems={Object.values(cart)} showId={showDetails ? showDetails.id : ''} goBack={handlePrevSection} goBackToCatalog={handlePrevToCatalog} goBackToContactInfo={handlePrevToContactInfo} />}
    </Form>
  </Container>
);
};

export default OrderForm;