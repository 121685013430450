import React from 'react';
import { Spinner, Button, Container, Row, Col } from 'react-bootstrap';
import logo from '../logo.png';

const FinalScreen = ({ isLoading, isError, isSuccess, showName }) => {
    if (isLoading) {
        return <Spinner animation="border" />;
    }

    if (isError) {
        return (
            <Container>
                <p>We apologize for the inconvenience. Please contact our support team.</p>
                <Button href={`mailto:support@showgear.com?subject=${encodeURIComponent(showName)} Order Form Error Support`}>Email Support</Button>
            </Container>
        );
    }

    if (isSuccess) {
        return (
            <Container className="the-final-container">
                <Row>
                    <Col>
                    <p>Thank you! Your order has been received and a Show Gear team member will be in contact soon!</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <a href="https://www.showgear.com">
                    <img src={logo} className='logo' alt="Show Gear Logo" />
                </a>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Button href="https://www.showgear.com">Find Out More About Showgear</Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    return null;
};

export default FinalScreen;
