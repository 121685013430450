import React, { useState, useEffect } from 'react';
import { fetchShows, fetchShowDetails } from '../api/airtableApi';
import './ShowSelection.css';

const ShowSelector = ({ onShowSelect }) => {
  const [shows, setShows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const showsData = await fetchShows();
        setShows(showsData);
        setLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleSelectShow = async (showName) => {
    try {
      console.log('Show selected:', showName); // Debugging log
      const showDetails = await fetchShowDetails(showName);
      if (showDetails && showDetails.fields) {
        console.log('Show details:', showDetails); // Debugging log
        onShowSelect(showDetails.fields.Name, showDetails.id);
      } else {
        console.error('Invalid show details:', showDetails);
      }
    } catch (error) {
      console.error('Error fetching show details:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="show-selection-wrapper">
      <div className="show-selection-container">
        <div className="show-list">
          {shows.map(show => (
            <div key={show.id} className="show-item" onClick={() => handleSelectShow(show.fields.Name)}>
              <div className="show-images">
                <img src={show.fields['Show Logo URL']} alt={show.fields.Name} />
              </div>
              <div className="show-title">
                <h5>Dates:</h5>
                <h6>{formatDate(show.fields['Show Start'])} - {formatDate(show.fields['Show Strike'])}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowSelector;