import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Container, Tabs, Tab, Table, Modal } from 'react-bootstrap';
import { fetchCatalogue, fetchShowDetails } from '../api/airtableApi';
import './OrderFormCatalog.css';

const OrderFormCatalog = ({ showName, goBack, proceed, cart, setCart }) => {
    const [groupedItems, setGroupedItems] = useState({});
    const [showDetails, setShowDetails] = useState(null);
    const [showMonitorModal, setShowMonitorModal] = useState(false);
    const [currentMonitorItem, setCurrentMonitorItem] = useState(null);
    const [monitorStandPrice, setMonitorStandPrice] = useState(null);
    const [monitorStandShelfPrice, setMonitorStandShelfPrice] = useState(null);
    const [monitorStandItem, setMonitorStandItem] = useState(null);
    const [monitorStandShelfItem, setMonitorStandShelfItem] = useState(null);
    const [showMicrophoneModal, setShowMicrophoneModal] = useState(false);
    const [currentPackageItem, setCurrentPackageItem] = useState(null);
    const [handheldMicPrice, setHandheldMicPrice] = useState(null);
    const [bodypackMicPrice, setBodypackMicPrice] = useState(null);
    const [handheldMicItem, setHandheldMicItem] = useState(null);
    const [bodypackMicItem, setBodypackMicItem] = useState(null);

    const groupItemsByCategory = (items) => {
        const grouped = items.reduce((acc, item) => {
            const category = item.fields.Category || 'Others';
            if (!acc[category]) acc[category] = [];
            acc[category].push(item);
            return acc;
        }, {});

        Object.keys(grouped).forEach(category => {
            grouped[category].sort((a, b) => a.fields.Autonumber - b.fields.Autonumber);
        });

        return grouped;
    };

    const findItemId = (itemName, groupedItems) => {
        for (const category in groupedItems) {
            const foundItem = groupedItems[category].find(item => item.fields.Name === itemName);
            if (foundItem) {
                console.log(`Found ${itemName} in category ${category}`, foundItem);
                return foundItem.id;
            }
        }
        console.log(`Item ${itemName} not found in any category`);
        return null;
    };

    const findItemById = (itemId, groupedItems) => {
        if (!itemId) return null;

        for (const category in groupedItems) {
            const foundItem = groupedItems[category].find(item => item.id === itemId);
            if (foundItem) {
                return foundItem;
            }
        }
        return null;
    };

    const setPricing = useCallback((itemsGroupedByCategory) => {
        const monitorStand = findItemById(findItemId("Dual Post Monitor Stand", itemsGroupedByCategory), itemsGroupedByCategory);
        const monitorStandShelf = findItemById(findItemId("Shelf (For Dual Post Monitor Stand)", itemsGroupedByCategory), itemsGroupedByCategory);
        const handheldMic = findItemById(findItemId("Wireless Handheld Microphone Kit", itemsGroupedByCategory), itemsGroupedByCategory);
        const bodypackMic = findItemById(findItemId("Wireless Lavaliere Microphone Kit (Bodypack)", itemsGroupedByCategory), itemsGroupedByCategory);
        
        if (monitorStand) {
            setMonitorStandItem(monitorStand);
            setMonitorStandPrice(monitorStand.fields);
        }
        if (monitorStandShelf) {
            setMonitorStandShelfItem(monitorStandShelf);
            setMonitorStandShelfPrice(monitorStandShelf.fields);
        }
        if (handheldMic) {
            setHandheldMicItem(handheldMic);
            setHandheldMicPrice(handheldMic.fields);
        }
        if (bodypackMic) {
            setBodypackMicItem(bodypackMic);
            setBodypackMicPrice(bodypackMic.fields);
        }
    }, []);

    const updateCart = (uniqueKey, item, delta, notes = '') => {
        setCart(prevCart => {
            const today = new Date();
            const advancedRateDueDate = new Date(showDetails.fields.ard);
            const isBeforeDueDate = today <= advancedRateDueDate;
            const pricePerItem = isBeforeDueDate ? item.fields['Advanced Rate'] : item.fields['Show Rate'];

            const newQuantity = (prevCart[uniqueKey]?.quantity || 0) + delta;
            const newSubtotal = pricePerItem * newQuantity;

            if (newQuantity <= 0) {
                const { [uniqueKey]: _, ...rest } = prevCart;
                return rest;
            } else {
                return {
                    ...prevCart,
                    [uniqueKey]: {
                        ...item,
                        name: item.fields.Name,
                        quantity: newQuantity,
                        subtotal: newSubtotal,
                        notes: notes
                    }
                };
            }
        });
    };

    const handleQuantityChange = (itemId, item, delta) => {
        console.log(`Attempting to change quantity for item: ${item.fields.Name}, delta: ${delta}`);
        const isMonitor = item.fields.Category === "Monitors";
        const isAdding = delta > 0;
    
        const isTourGuidePackage = item.fields.Category === "Packages" && ["Large Tour Guide Package", "Small Tour Guide Package", "Medium Tour Guide Package"].includes(item.fields.Name);
    
        if (isMonitor && isAdding) {
            console.log(`Monitor item added: ${item.fields.Name}`);
            setCurrentMonitorItem({ itemId, item });
            setShowMonitorModal(true);
        } else if (isTourGuidePackage && isAdding) {
            console.log(`Tour guide package item added: ${item.fields.Name}`);
            setCurrentPackageItem({ itemId, item });
            setShowMicrophoneModal(true);
        } else {
            const uniqueKey = `${itemId}-${new Date().getTime()}`;
            updateCart(uniqueKey, item, delta);
        }
    };
    useEffect(() => {
        console.log(`Monitor modal should show: ${showMonitorModal}, Current Monitor Item: ${JSON.stringify(currentMonitorItem)}`);
    }, [showMonitorModal, currentMonitorItem]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const items = await fetchCatalogue();
                const details = showName ? await fetchShowDetails(showName) : null;
                const itemsGroupedByCategory = groupItemsByCategory(items);
                setShowDetails(details);
                setGroupedItems(itemsGroupedByCategory);
                setPricing(itemsGroupedByCategory);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [showName, setPricing]);

    useEffect(() => {
        console.log("Cart on mount:", cart);
    }, [cart]);

    const renderPriceChangeHeader = () => {
        if (showDetails && showDetails.fields) {
            const advancedRateDueDate = showDetails.fields['ard'];
            const showRateDueDate = showDetails.fields['srd'];
            const options = { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'America/Los_Angeles' };

            const formattedAdvancedRateDueDate = advancedRateDueDate 
                ? new Date(advancedRateDueDate).toLocaleDateString('en-US', options) 
                : 'Unavailable';
            const formattedShowRateDueDate = showRateDueDate 
                ? new Date(showRateDueDate).toLocaleDateString('en-US', options) 
                : 'Unavailable';

            return (
                <div>
                    <h4>Rate Change Notice</h4>
                    <p>Advanced Rate Due Date: {formattedAdvancedRateDueDate}</p>
                    <p>Show Rate Due Date: {formattedShowRateDueDate}</p>
                </div>
            );
        }
        return <div>Loading show details...</div>;
    };

    const renderCart = () => (
        <div className="cart-section">
            <h5>Note: Click the plus button to add items to the catalog section below, then review the selections before clicking proceed.</h5>
            <h4>Cart</h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Notes</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(cart).map(([uniqueKey, item]) => (
                        <tr key={uniqueKey}>
                            <td>{item.name}</td>
                            <td>{item.notes}</td>
                            <td>{item.quantity}</td>
                            <td>${item.subtotal.toFixed(2)}</td>
                            <td>
                                <Button variant="light" onClick={() => updateCart(uniqueKey, item, -item.quantity)}>X</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="subtotal">
Subtotal:
${Object.values(cart).reduce((acc, item) => acc + item.subtotal, 0).toFixed(2)}

            </div>
        </div>
    );

    const renderSelectionModal = (showModal, setShowModal, currentItem, options, title, itemFieldName) => {
        if (!currentItem) return null;
        console.log(`Rendering ${title.toLowerCase()} modal`);
    
        const today = new Date();
        const advancedRateDueDate = new Date(showDetails.fields.ard);
        const isBeforeDueDate = today <= advancedRateDueDate;
    
        const handleSelection = (type, extraItems = []) => {
            setShowModal(false);
            const { itemId, item } = currentItem;
    
            const uniqueKey = `${itemId}-${type.replaceAll(" ", "_")}`;
            const notes = `${itemFieldName}: ${type}`;
            updateCart(uniqueKey, item, 1, notes);
    
            extraItems.forEach(({ uniqueKeyPrefix, extraItem, extraNotes }) => {
                if (extraItem) {
                    const uniqueExtraKey = `${uniqueKeyPrefix}-${itemId}-${type.replaceAll(" ", "_")}`; // <span style="color:red">Ensure unique key for extra items includes item ID and type</span>
                    updateCart(uniqueExtraKey, extraItem, 1, extraNotes);
                }
            });
        };
    
        const getButtonLabel = (price, label) => {
            if (!price) return label;
            const formattedPrice = isBeforeDueDate ? price['Advanced Rate'] : price['Show Rate'];
            return `${label} - ${formattedPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
        };
    
        return (
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-stretch">
                        {options.map(option => (
                            <Button key={option.type} className="mb-2" onClick={() => handleSelection(option.type, option.extraItems)}>
                                {getButtonLabel(option.price, option.label)}
                            </Button>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const renderMonitorModal = () => {
        const monitorOptions = [
            { label: "TV Stand", type: "TV Stand", price: null },
            { label: "Wall Mounted", type: "Wall Mounted", price: null },
            { label: "Dual Post Monitor Stand", type: "Dual Post Monitor Stand", price: monitorStandPrice, extraItems: [{ uniqueKeyPrefix: 'stand', extraItem: monitorStandItem, extraNotes: `Stand for ${currentMonitorItem?.item.fields.Name}` }] },
            { label: "Dual Post Monitor Stand with Shelf", type: "Dual Post Monitor Stand with Shelf", price: (monitorStandPrice && monitorStandShelfPrice) ? { 'Advanced Rate': monitorStandPrice['Advanced Rate'] + monitorStandShelfPrice['Advanced Rate'], 'Show Rate': monitorStandPrice['Show Rate'] + monitorStandShelfPrice['Show Rate'] } : null, extraItems: [{ uniqueKeyPrefix: 'stand', extraItem: monitorStandItem, extraNotes: `Stand for ${currentMonitorItem?.item.fields.Name}` }, { uniqueKeyPrefix: 'shelf', extraItem: monitorStandShelfItem, extraNotes: `Shelf for ${currentMonitorItem?.item.fields.Name}` }] }
        ];
    
        return renderSelectionModal(showMonitorModal, setShowMonitorModal, currentMonitorItem, monitorOptions, "Monitor Display Option", "Display Option");
    };
    
    const renderMicrophoneModal = () => {
        const microphoneOptions = [
            { label: "Wired Microphone", type: "Wired Microphone", price: null },
            { label: "Wireless Handheld Microphone Kit", type: "Wireless Handheld Microphone Kit", price: handheldMicPrice, extraItems: [{ uniqueKeyPrefix: 'mic', extraItem: handheldMicItem, extraNotes: `Handheld Microphone for ${currentPackageItem?.item.fields.Name}` }] },
            { label: "Wireless Lavaliere Microphone Kit (Bodypack)", type: "Wireless Lavaliere Microphone Kit (Bodypack)", price: bodypackMicPrice, extraItems: [{ uniqueKeyPrefix: 'mic', extraItem: bodypackMicItem, extraNotes: `Bodypack Microphone for ${currentPackageItem?.item.fields.Name}` }] }
        ];
    
        return renderSelectionModal(showMicrophoneModal, setShowMicrophoneModal, currentPackageItem, microphoneOptions, "Microphone Option", "Microphone Option");
    };
    
    const renderQuantityColumn = (item) => (
        <td>
            <div className="quantity-container">
                <Button variant="outline-success" size="sm" onClick={() => handleQuantityChange(item.id, item, 1)}>+</Button>
            </div>
        </td>
    );
    
    const orderedTabKeys = ['Packages', 'Monitors', 'Audio', 'Computers', 'Lighting'];
    
    const renderTabs = () => (
        <Tabs defaultActiveKey="Packages" id="category-tabs">
            {orderedTabKeys.map(category => {
                if (!groupedItems[category]) return null;
                return (
                    <Tab key={category} eventKey={category} title={category}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Advanced Rate</th>
                                    <th>Show Rate</th>
                                    <th>Add to Cart</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupedItems[category].map(item => (
                                    <tr key={item.id}>
                                        <td>{item.fields.Name}</td>
                                        <td>{item.fields.Description}</td>
                                        <td>{item.fields["Advanced Rate"]?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || '-'}</td>
                                        <td>{item.fields["Show Rate"].toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        {renderQuantityColumn(item)}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                );
            })}
        </Tabs>
    );
    
    return (
        <Container className="mt-5">
            <h2>Order Form for {showDetails ? showDetails.fields.Name : 'Loading...'}</h2>
            <Card>
                <Card.Header>{renderPriceChangeHeader()}</Card.Header>
                <Card.Body>
                    <div className="cart-container">
                        {renderCart()}
                    </div>
                    <div className="actions">
                        <Button variant="secondary" onClick={goBack}>Back</Button>
                        <Button variant="primary" onClick={() => proceed(Object.values(cart))}>Proceed</Button>
                    </div>
                    <h3>Catalog Selection</h3>
                    {renderTabs()}
                </Card.Body>
            </Card>
            {renderMonitorModal()}
            {renderMicrophoneModal()}
        </Container>
    );
}

export default OrderFormCatalog;