import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image, Container, Row, Col } from 'react-bootstrap';
import ShowSelector from './components/ShowSelector';
import OrderForm from './components/OrderForm';
import { fetchShowDetails } from './api/airtableApi';
import logo from './logo.png';
import './App.css';

const App = () => {
    return (
        <Router>
            <AppContent />
        </Router>
    );
};

const AppContent = () => {
    const { showName } = useParams();
    const [selectedShow, setSelectedShow] = useState(null);
    const [selectedShowDetails, setSelectedShowDetails] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const loadShowDetails = useCallback(async (showName) => {
        try {
            console.log('Loading show details for:', showName); // Debugging log
            const details = await fetchShowDetails(showName);
            if (details && details.fields) {
                setSelectedShowDetails(details);
                setThemeColors(details.fields);
            } else {
                console.error('Invalid show details:', details);
            }
        } catch (err) {
            setError(err.message);
        }
    }, []);

    useEffect(() => {
        if (showName) {
            console.log('Show name from URL params:', showName); // Debugging log
            loadShowDetails(showName);
        }
    }, [showName, loadShowDetails]);

    useEffect(() => {
        if (error) {
            console.log('Error fetching show details:', error);
        }
    }, [error]);

    const handleShowSelect = (showName, showId) => {
        console.log('Selected show:', showName, showId); // Debugging log
        setSelectedShow(showName);
        navigate(`/show/${showName}`);
        loadShowDetails(showName);
    };

    const setThemeColors = (fields) => {
        if (fields) {
            document.documentElement.style.setProperty('--theme-color-1', fields.HEX1 || '#f08000');
            document.documentElement.style.setProperty('--theme-color-2', fields.HEX2 || '#f08000');
            document.documentElement.style.setProperty('--theme-color-3', fields.HEX3 || '#f08000');
            document.documentElement.style.setProperty('--theme-color-4', fields.HEX4 || '#f08000');
        }
    };

    const [isHovering, setIsHovering] = useState(false);

    const formatDate = (dateString) => {
        const options = { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    };

    return (
        <Container>
            <Row className='order-header'>
                <Col>
                    <div className="tooltip-container">
                        <a href="https://www.showgear.com" target="_blank" rel="noopener noreferrer">
                            <Image 
                                style={{ maxWidth: "200px" }} 
                                src={logo} 
                                alt="Show Gear Logo" 
                                onMouseEnter={() => setIsHovering(true)}
                                onMouseLeave={() => setIsHovering(false)}
                            />
                        </a>
                        {isHovering && <div className="tooltip">To find out more information click here</div>}
                    </div>
                </Col>
                <Col>
                    <h2>Exhibitor Order Form</h2>
                </Col>
            </Row>

            {selectedShowDetails && (
                <Row className='order-show'>
                    <Col className='order-show-img'>
                        <Image style={{ maxWidth: "200px" }} src={selectedShowDetails.fields["Show Logo URL"]} alt={selectedShowDetails.fields.Name} />
                    </Col>
                    <Col>
                        <p><strong>Venue:</strong> {selectedShowDetails.fields.Venue}</p>
                        <p><strong>Show Dates: </strong> 
                            {formatDate(selectedShowDetails.fields["Show Start"])}
                            {" to "}
                            {formatDate(selectedShowDetails.fields["Show Strike"])}
                        </p>
                        <p><strong>Advanced Rate Due Date:</strong> {formatDate(selectedShowDetails.fields["ard"])}</p>
                        <p><strong>Show Rate Due Date:</strong> {formatDate(selectedShowDetails.fields["srd"])}</p>
                    </Col>
                </Row>
            )}

            <Routes>
                <Route path="/" element={<ShowSelector onShowSelect={handleShowSelect} />} />
                <Route path="/show/:showName" element={<OrderForm showDetails={selectedShowDetails} />} />
            </Routes>
        </Container>
    );
};

const NavigateHandler = ({ selectedShowName }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedShowName) {
            navigate(`/show/${selectedShowName}`);
        }
    }, [selectedShowName, navigate]);

    return null;
};

export default App;
